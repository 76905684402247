<template>
  <div>
    <div id="router" class="pt-10">
      <v-row class="justifyCenter" style="margin: 1% 8%">
        <v-col class="text-left pr-6 col-11 col-md-5" align-self="center">
          <p class="styleH4">資訊系統的建置/優化</p>
          <p class="styleH2">提供專業系統引導與規劃</p>
          <p class="styleT2" style="color:#737373">每個專案皆配有專案經理，明確訂定需求與權責範圍，統一窗口討論系統形象設計、程式建置與上線前測試。</p>
          <v-row class="ma-0 pt-4">
            <v-btn class="py-4 px-6 styleBtn custom-btn" outlined @click="redirectPath('/contactUs')">
              <h4 class="center">立即詢問</h4>
              <v-icon class="pa-2">mdi-email-outline</v-icon>
            </v-btn>
          </v-row>
        </v-col>
        <v-col class="px-6 col-11 col-md-4" align="center">
          <v-img :src="images.action_04" width=400 alt="action_04"></v-img>
        </v-col>
      </v-row>

      <v-row v-if="innerWidth >= 700" class="justifyCenter" style="margin:8% 8% 2% 8%; padding: 1% 0%; background:#FFF">
        <div v-for="(section, index) in sections" :key="section.id"  
          class="finger section-item" :class="{ 'section-item-hover': hoverIndex === index }" 
          :style="[{'padding' : (innerWidth > 1200) ? '40px' : '20px'}]"
          @click="scrollToSection(section.id)" @mouseover="hoverIndex = index" @mouseleave="hoverIndex = null">
          <p class="pa-0 styleT2" :class="{ 'hover-text': hoverIndex === index }">
            {{ section.title }}
          </p>
          <div :class="{ 'icon-hover-div': hoverIndex === index }">
            <v-icon :class="{ 'icon-hover': hoverIndex === index }" :color="hoverIndex === index ? '#4880C8' : '#000'" size="28">
              mdi-chevron-down
            </v-icon>
          </div>
        </div>
      </v-row>

      <v-row id="section1" class="justifyCenter" style="margin:5% 8%; padding:3% 0%; background:#FFF" data-aos="fade-right">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter" style="position: relative;">
          <div class="image_container">
            <v-img class="image" :src="images.mockup_1" alt="mockup_1"></v-img>
            <div class="overlay">
              <h2 style="color: #FFF;">雙平台混合式開發</h2>
            </div>
          </div>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">客製化行動裝置/APP</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">本App為亞東科技大學全校教職員生及校友提供具智慧化與整合性的資訊服務，系統支援接收推播資訊功能、個人資訊管理及整合性資訊資源</p>
        </v-col>
      </v-row>
      
      <v-row id="section2" v-if="innerWidth >= 960" class="justifyCenter" style="margin:5% 8%; padding:3% 0%; background:#FFF" data-aos="fade-left">
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">資訊管理系統</p>
          <p class="styleT4 pt-4" style="line-height:28px">本專案重新梳理/優化操作流程，同時追加特色功能以改善使用者多系統切換的操作痛點。五大系統分別為：考試報名系統、管理後台、題庫系統、監考系統及考試系統</p>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter" style="position: relative;">
          <div class="image_container">
            <v-img class="image" :src="images.mockup_2" alt="mockup_2"></v-img>
            <div class="overlay">
              <h2 style="color: #FFF;">前後端分離架構</h2>
            </div>
          </div>
        </v-col>
      </v-row>
      
      <v-row id="section2" v-if="innerWidth < 960" class="justifyCenter" style="margin:5% 8%; padding:3% 0%; background:#FFF" data-aos="fade-left">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter" style="position: relative;">
          <div class="image_container">
            <v-img class="image" :src="images.mockup_2" alt="mockup_2"></v-img>
            <div class="overlay">
              <h2 style="color: #FFF;">前後端分離架構</h2>
            </div>
          </div>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">資訊管理系統</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">依照需求進行各類網站建置、語系切換、特定資訊及功能串接等特色功能。網站類別可分為官方網站、一站式網站、導購式網頁、智慧管理後台等</p>
        </v-col>
      </v-row>
      
      <v-row id="section3" class="justifyCenter" style="margin:5% 8%; padding:3% 0%; background:#FFF" data-aos="fade-right">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter" style="position: relative;">
          <div class="image_container">
            <v-img class="image" :src="images.mockup_3" alt="mockup_3"></v-img>
            <div class="overlay">
              <h2 style="color: #FFF;">企業/單位專屬平台</h2>
            </div>
          </div>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">客製化網站</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">依照需求進行各類網站建置、語系切換、特定資訊及功能串接等特色功能。網站類別可分為官方網站、一站式網站、導購式網頁、智慧管理後台等</p>
        </v-col>
      </v-row>
      
      <v-row id="section4" v-if="innerWidth >= 960" class="justifyCenter" style="margin:5% 8%; padding:3% 0%; background:#FFF" data-aos="fade-left">
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">設計專項團隊</p>
          <p class="styleT4 pt-4" style="line-height:28px">系統開發以 Wireframe 進行初期討論，最終提供完整 UI介面設計，視需求製作上架圖文、系統推廣短片、海報、周邊商品等</p>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter" style="position: relative;">
          <div class="image_container">
            <v-img class="image" :src="images.mockup_4" alt="mockup_4"></v-img>
            <div class="overlay">
              <h2 style="color: #FFF;">各項商用設計</h2>
            </div>
          </div>
        </v-col>
      </v-row>
      
      <v-row id="section4" v-if="innerWidth < 960" class="justifyCenter" style="margin:5% 8%; padding:3% 0%; background:#FFF" data-aos="fade-left">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter" style="position: relative;">
          <div class="image_container">
            <v-img class="image" :src="images.mockup_4" alt="mockup_4"></v-img>
            <div class="overlay">
              <h2 style="color: #FFF;">各項商用設計</h2>
            </div>
          </div>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">設計專項團隊</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">系統開發以 Wireframe 進行初期討論，最終提供完整 UI介面設計，視需求製作上架圖文、系統推廣短片、海報、周邊商品等</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseStudy',
  components: {},
  data:() => ({
    scrollNum: 0, //滾動距離
    hover: [false, false, false, false],
    cursorX: 0,
    cursorY: 0,
    viewStyle:{},
    innerWidth: 0,
    images:{
      action_04: require('@/assets/image/action_04.png'),
      mockup_1: require('@/assets/image/mockup_1.png'),
      mockup_2: require('@/assets/image/mockup_2.png'),
      mockup_3: require('@/assets/image/mockup_3.png'),
      mockup_4: require('@/assets/image/mockup_4.png'),
    },
    sections: [
      { id: 'section1', title: '客製化行動裝置' },
      { id: 'section2', title: '資訊管理系統' },
      { id: 'section3', title: '客製化網站' },
      { id: 'section4', title: '設計專項團隊' }
    ],
    hoverIndex: null,
  }),
  created(){
    this.calculateWindows()
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    sessionStorage.setItem('login', 'false')
  },
  methods:{
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    calculateWindows(){
      this.innerWidth = window.innerWidth
      this.viewStyle = {height: (window.innerHeight-((window.innerWidth > 959) ? 100 : 121)) + 'px'}
    },
  }
}
</script>