<template>
  <div>
    <!-- https://nightly.ckeditor.com/22-06-18-06-03/full/samples/index.html?fbclid=IwAR1bFbCixcoFDY_jxXofXaZikxY_zNIvIDfzY-kvgRI5_mu0F1XnOeBI5Tw -->
    <!-- 回到頂部 -->
      <div id="router">
        <div style="padding: 5% 0% 8% 0%">
          <p class="styleH4">面對資訊系統的建置/優化</p>
          <p class="styleH2">您是否也有這種疑問？</p>

          <v-row class="ma-1 mt-8 justifyCenter" style="padding:1% 5%;">
            <v-col class="col-11 col-md-5">
              <v-img :src="images.action_01" alt="action_01"></v-img>
            </v-col>
            <v-col class="col-11 col-md-4 py-6" align-self="center">
              <v-row align="center" data-aos="fade-up" data-aos-duration="1500">
                <v-col cols="2" class="px-1 py-2">
                  <v-img :src="images.icon_doubt_01" width=75 alt="icon_doubt_01"></v-img>
                </v-col>
                <v-col cols="10" class="pa-0 pl-2" align="left">
                  <p class="styleT2">不知道從何開始？</p>
                  <p class="styleT4">舊系統架構複雜，功能及分支眾多</p>
                </v-col>
              </v-row>
              <v-row align="center" data-aos="fade-up" data-aos-duration="2000">
                <v-col cols="2" class="px-1 py-2">
                  <v-img :src="images.icon_doubt_02" width=75 alt="icon_doubt_02"></v-img>
                </v-col>
                <v-col cols="10" class="pa-0 pl-2" align="left">
                  <p class="styleT2">沒有時間多方尋找廠商？</p>
                  <p class="styleT4">如何作為系統設計與開發中間的橋樑</p>
                </v-col>
              </v-row>
              <v-row align="center" data-aos="fade-up" data-aos-duration="2500">
                <v-col cols="2" class="px-1 py-2">
                  <v-img :src="images.icon_doubt_03" width=75 alt="icon_doubt_03"></v-img>
                </v-col>
                <v-col cols="10" class="pa-0 pl-2" align="left">
                  <p class="styleT2">如何提高使用者體驗？</p>
                  <p class="styleT4">希望使用流程較為直覺，易操作</p>
                </v-col>
              </v-row>
              <v-row align="center" data-aos="fade-up" data-aos-duration="3000">
                <v-col cols="2" class="px-1 py-2">
                  <v-img :src="images.icon_doubt_04" width=75 alt="icon_doubt_04"></v-img>
                </v-col>
                <v-col cols="10" class="pa-0 pl-2" align="left">
                  <p class="styleT2">擔心系統維護與資訊安全？</p>
                  <p class="styleT4">找尋好配合的廠商且能夠快速給予協助</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-row class="ma-0" justify="center"> 
          <div class="ori_screen_image" :class="{ fullWidth: isColored }">
            <v-row class="justifyCenter full_screen_image" :class="{ colored: isColored }" 
            style="margin: 1% 0% 5% 0%; background:#FFF">
              <v-col class="col-11 col-md-3 pa-5" align="center">
                <div style="width:200px">
                  <v-img :src="images.logo_chihta" alt="logo_chihta"></v-img>
                </div>
                <p class="styleT4 mt-5" style="font-size:18px">新北市鶯歌區尖山路101巷4弄2號</p>
                <p class="styleT4" style="font-size:18px">chihta.software@gmail.com</p>
              </v-col>
              <v-col class="col-11 col-md-5 pa-5" align-self="center" align="left">
                <v-row class="ma-0 pa-0">
                  <p class="styleH4" style="color:#4880C8">智達軟體設計</p>
                  <p class="styleH4">・解決您的困擾</p>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <p class="styleT4 pt-2" style="color:#737373">本公司成立於2021年，以開啟著的圓形開關鍵，象徵本公司為客戶提供之軟體設計服務及視覺設計服務 Always ON 的決心</p>
                </v-row>
                <div class="mx-0 pa-0 mt-5" style="width:220px;background:#4880C8">
                  <h6 class="styleT4 py-auto pl-3" style="color:#FFF; font-size:1.25rem">服務項目</h6>
                </div>
                <v-row class="ma-0 pa-0">
                  <p class="styleT4 pt-2" style="color:#737373">資訊軟體服務、系統建置服務、資訊處理服務、一般廣告服務、產品設計服務，為客戶打造網路應用程式、行動應用程式、網站設計與建制、企業內部系統</p>
                </v-row>
              </v-col>
              <!-- <v-col class="col-11 col-md-3 pa-5" align="center">
                <v-img :src="images.logo_chihta" width=180 alt="logo_chihta"></v-img>
              </v-col>
              <v-col class="col-11 col-md-5 pa-5" align-self="center" align="left">
                <v-row class="ma-0 pa-0">
                  <p class="styleH4" style="color:#4880C8">智達軟體設計</p>
                  <p class="styleH4">・解決您的困擾</p>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <p class="styleT4 pt-2" style="color:#737373">本公司成立於2021年，以開啟著的圓形開關鍵，象徵本公司為客戶提供之軟體設計服務及視覺設計服務 Always ON 的決心</p>
                </v-row>
              </v-col> -->
            </v-row>
          </div>
        </v-row>

        <div class="pa-0" style="margin: 6% 12% 3% 12%">
          <p class="styleH4">深耕軟體及設計產業</p>
          <p class="styleH2">三大領域・完整服務</p>
        </div>

        <v-row style="justify-content:space-around; margin: 1% 12% 0% 12%">
          <v-col class="ma-2 pa-0 col-11 col-md-3 floating-box" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" style="background:#FFF; box-shadow: 3px 5px 9px -1px #00000014;">
            <div class="pt-6 pb-3 justifyCenter" style="border-bottom: 1px solid #737373">
              <v-img class="mx-auto" :src="images.service_01" width=260 alt="logo_chihta"></v-img>
            </div>
            <div class="pa-6 text-left">
              <p class="styleT2">醫療領域</p>
              <p class="styleT4 pt-1">給藥追蹤系統｜手術室看板｜診所APP｜智能管理平台</p>
            </div>
          </v-col>
          <v-col class="ma-2 pa-0 col-11 col-md-3 floating-box" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" style="background:#FFF; box-shadow: 3px 5px 9px -1px #00000014;">
            <div class="pt-6 pb-3 justifyCenter" style="border-bottom: 1px solid #737373">
              <v-img class="mx-auto" :src="images.service_02" width=260 alt="logo_chihta"></v-img>
            </div>
            <div class="pa-6 text-left">
              <p class="styleT2">教育領域</p>
              <p class="styleT4 pt-1">校園智慧校園行動APP｜認證考試報名系統｜監考系統｜考試系統</p>
            </div>
          </v-col>
          <v-col class="ma-2 pa-0 col-11 col-md-3 floating-box" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" style="background:#FFF; box-shadow: 3px 5px 9px -1px #00000014;">
            <div class="pt-6 pb-3 justifyCenter" style="border-bottom: 1px solid #737373">
              <v-img class="mx-auto" :src="images.service_03" width=260 alt="logo_chihta"></v-img>
            </div>
            <div class="pa-6 text-left">
              <p class="styleT2">其他領域</p>
              <p class="styleT4 pt-1">硬件整合｜物聯網開發｜智慧工廠及智慧城市</p>
            </div>
          </v-col>
        </v-row>
        
        <v-row class="ma-0 pa-0" justify="center">
          <v-row class="ma-0 pa-0 mt-12" style="max-width: 70%; overflow: hidden; position: relative;">
            <div class="images_carousel" @mouseenter="stopAnimation" @mouseleave="startAnimation">
              <div class="images_carousel_inner" ref="carousel">
                <div v-for="(item, i) in imageCarouselList"
                  :key="i"
                  class="images_container pa-2"
                  style="width: 300px; display: inline-block;">
                  <v-img eager :src="item" />
                </div>
                <div v-for="(item, i) in imageCarouselList"
                  :key="'clone-' + i"
                  class="images_container pa-2"
                  style="width: 300px; display: inline-block;">
                  <v-img eager :src="item" />
                </div>
              </div>
            </div>
          </v-row>
        </v-row>

        <v-row class="justifyCenter" style="margin: 12% 8%">
          <v-col class="text-left pr-6 col-12 col-md-6" align-self="center">
            <p class="styleH4">解決您對於專案執行流程的各項疑惑</p>
            <p class="styleH2">專業客製化軟體開發團隊</p>
            <p class="styleT2" style="color:#737373">依照專案特性與範疇建立專屬開發團隊，期間以使用者體驗為導向進行系統設計，滿足各式使用者的作業需求，用以提高作業效率與同業競爭力</p>
            <v-row class="ma-0 pt-4">
              <v-btn class="py-4 px-6 styleBtn custom-btn" outlined @click="redirectPath('/service')">
                <h4 class="center">查看服務項目</h4>
                <v-icon>mdi-arrow-right-thin</v-icon>
              </v-btn>
              <v-btn v-if="innerWidth >= 588" class="py-4 px-6 ml-2 styleBtn custom-btn" outlined @click="redirectPath('/contactUs')">
                <h4 class="center">發信聯絡智達</h4>
                <v-icon>mdi-arrow-right-thin</v-icon>
              </v-btn>
            </v-row>
          </v-col>
          <v-col class="px-6 col-11 col-md-4" align="center">
            <v-img :src="images.action_02" width=400 alt="action_02"></v-img>
          </v-col>
        </v-row>
      </div>
    </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: {},
  data:() => ({
    viewStyle:{},
    isColored: false,
    scrollNum: 0, //滾動距離
    innerWidth: 0,
    images:{
      logo_chihta: require('@/assets/image/logo_chihta.png'),
      action_01: require('@/assets/image/action_01.png'),
      action_02: require('@/assets/image/action_02.png'),
      service_01: require('@/assets/image/service_01.png'),
      service_02: require('@/assets/image/service_02.png'),
      service_03: require('@/assets/image/service_03.png'),
      icon_doubt_01: require('@/assets/image/icon_doubt_01.png'),
      icon_doubt_02: require('@/assets/image/icon_doubt_02.png'),
      icon_doubt_03: require('@/assets/image/icon_doubt_03.png'),
      icon_doubt_04: require('@/assets/image/icon_doubt_04.png'),
    },
    imageCarouselList:[
      require('@/assets/image/mockup_1.png'),
      require('@/assets/image/mockup_2.png'),
      require('@/assets/image/mockup_3.png'),
      require('@/assets/image/mockup_4.png'),
      require('@/assets/image/mockup_5.png'),
    ]
  }),
  created(){
    this.calculateWindows()
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.handleScroll();
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    calculateWindows(){
      this.innerWidth = window.innerWidth
      this.viewStyle = {height: (window.innerHeight-((window.innerWidth > 959) ? 100 : 121)) + 'px'}
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    handleScroll() {
      const scrollTop = window.scrollY;
      // 根据滚动的位置来切换图片的滤镜效果
      this.isColored = scrollTop > 350; // 当滚动超过100px时变为彩色
    },
    stopAnimation() {
      this.$refs.carousel.style.animationPlayState = 'paused';
    },
    startAnimation() {
      this.$refs.carousel.style.animationPlayState = 'running';
    }
  }
}
</script>