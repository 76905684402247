<template>
  <div>
    <!-- 工具栏区域 -->
    <div class="toolbar">
      <button @click="setAlignment('left')">左对齐</button>
      <button @click="setAlignment('center')">居中</button>
      <button @click="setAlignment('right')">右对齐</button>
    </div>

    <!-- 编辑器区域 -->
    <div ref="editor" class="editor-wrapper"></div>
  </div>

</template>

<script>
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import Marker from '@editorjs/marker';
import InlineCode from '@editorjs/inline-code';
import Warning from '@editorjs/warning';
import Table from '@editorjs/table';
import Delimiter from '@editorjs/delimiter';
import LinkTool from '@editorjs/link';
import Raw from '@editorjs/raw';

import ColorTool from './ColorTool'; // 导入自定义换色工具
import AlignTool from './AlignTool'; // 引入自定义对齐工具

import personality from '@editorjs/personality';
import Embed from '@editorjs/embed';
import Image from '@editorjs/image';

export default {
  name: 'EditorName',
  mounted() {
    console.log('Editor instance:', this.editor);
    this.editor = new EditorJS({
      holder: this.$refs.editor,
      tools: {
        header: Header,
        list: List,
        checklist: Checklist,
        embed: Embed,
        delimiter: Delimiter,
        raw: Raw,
        align: AlignTool, // 配置自定义对齐工具
        marker: {
          class: Marker,
          shortcut: 'CMD+M'
        },
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+E'
        },
        warning: {
          class: Warning,
          config: {
            titlePlaceholder: 'Title',
            messagePlaceholder: 'Message',
          },
        },
        image: {
          class: Image,
          config: {
            endpoints: {
              byFile: 'http://localhost:8008/uploadFile', // 你的上传文件端点
              byUrl: 'http://localhost:8008/fetchUrl', // 你的通过 URL 上传端点
            }
          }
        },
        table: {
          class: Table,
          inlineToolbar: true,
        },
        personality: {
          class: personality,
          config: {
            endpoint: 'http://localhost:8008/uploadFile'  // Your backend file uploader endpoint
          }
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: 'http://localhost:8008/fetchUrl', // 你的通过 URL 获取元数据端点
          }
        },
        color: {
          class: ColorTool,
          shortcut: 'CMD+K' // 设置快捷键
        }
      },
      data: {
        blocks: [
          {
            type: 'header',
            data: {
              text: 'Editor.js in Vue',
              level: 2
            }
          },
          {
            type: 'paragraph',
            data: {
              text: 'This is an example of using Editor.js with Vue.'
            }
          },
          
        ]
      },
      onReady: () => {
        console.log('Editor.js is ready to work!');
      },
      onChange: () => {
        console.log('Now I know that Editor\'s content changed!');
      }
    });
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  },
  methods: {
    setAlignment(align) {
      console.log('[A]setAlignment');
      try {
        this.editor.blocks.insert('align', { align: align });
        console.log('[B]setAlignment');
      } catch (error) {
        console.error('Error in setAlignment:', error);
      }
    }
  }
}
</script>

<style scoped>

.editor-wrapper {
  border: 1px solid #ddd;
  padding: 10px;
  min-height: 300px;
  width: 100%;
}

.align-button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.align-button.active {
  background-color: #f0f0f0;
}

.toolbar {
  margin-bottom: 10px;
}

.toolbar button {
  margin-right: 5px;
}
</style>
