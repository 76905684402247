<template>
  <div>
    <div id="router" class="pt-10" :style="viewStyle">

      <div>
        <Editor />
      </div>
      <svg width="200" height="200" viewBox="0 0 100 100">
        <!-- Outer Circle -->
        <circle cx="50" cy="50" r="30" fill="#401212">
          <animate
            attributeName="r"
            values="30;40;30"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </circle>
        <!-- Middle Circle -->
        <circle cx="50" cy="50" r="20" fill="#802425">
          <animate
            attributeName="r"
            values="20;25;20"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </circle>
        <!-- Inner Circle -->
        <circle cx="50" cy="50" r="10" fill="#D63C3D">
          <animate
            attributeName="r"
            values="10;20;10"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
      <v-row class="justifyCenter" style="margin: 1% 8%">
        <v-col class="text-left pr-6 col-11 col-md-5" align-self="center">
          <p class="styleH4">資訊系統的建置/優化</p>
          <p class="styleH2">提供專業系統引導與規劃</p>
          <p class="styleT2" style="color:#737373">每個專案皆配有專案經理，明確訂定需求與權責範圍，統一窗口討論系統形象設計、程式建置與上線前測試。</p>
          <v-row class="ma-0 pt-4">
            <v-btn class="pa-4 styleBtn" outlined style="color:#4880C8" @click="redirectPath('/contactUs')">
              <h4 class="center">立即詢問</h4>
              <v-icon class="pa-2">mdi-email-outline</v-icon>
            </v-btn>
          </v-row>
        </v-col>
        <v-col class="px-6 col-11 col-md-4" align="center">
          <v-img :src="images.action_04" width=400 alt="action_04"></v-img>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" justify="center">
        <v-row class="ma-0 pa-0" style="max-width: 60%; overflow: hidden; position: relative;">
          <div class="carousel">
            <div class="carousel-inner">
              <div class="image-container">
                <v-img :src="images.imgLong" width="1800" alt="imgLong"></v-img>
                <v-img :src="images.imgLong" width="1800" alt="imgLong"></v-img>
                <v-img :src="images.imgLong" width="1800" alt="imgLong"></v-img>
                <v-img :src="images.imgLong" width="1800" alt="imgLong"></v-img>
                <v-img :src="images.imgLong" width="1800" alt="imgLong"></v-img> <!-- 添加一张图片确保无缝滚动 -->
              </div>
            </div>
          </div>
        </v-row>
      </v-row>
      
      <v-row class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <v-img class="finger" :src="hover[0] ? images.mockup_1_mask : images.mockup_1" alt="mockup_1" @mousemove="onMouseMove" @mouseover="onMouse('over',0)" @mouseleave="onMouse('leave',0)"></v-img>
          <div v-if="hover[0]" :style="{ top: cursorY + 'px', left: cursorX + 'px' }" class="arrow-icon">
            <v-icon color="#FFF" size="28">mdi-arrow-right</v-icon>
          </div>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">客製化行動裝置/APP</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">本App為亞東科技大學全校教職員生及校友提供具智慧化與整合性的資訊服務，系統支援接收推播資訊功能、個人資訊管理及整合性資訊資源</p>
        </v-col>
      </v-row>
      
      <v-row v-if="innerWidth >= 960" class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">資訊管理系統</p>
          <p class="styleT4 pt-4" style="line-height:28px">本專案重新梳理/優化操作流程，同時追加特色功能以改善使用者多系統切換的操作痛點。五大系統分別為：考試報名系統、管理後台、題庫系統、監考系統及考試系統</p>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <v-img class="finger" :src="hover[1] ? images.mockup_2_mask : images.mockup_2" @mousemove="onMouseMove" @mouseover="onMouse('over',1)" @mouseleave="onMouse('leave',1)"></v-img>
          <div v-if="hover[1]" :style="{ top: cursorY + 'px', left: cursorX + 'px' }" class="arrow-icon">
            <v-icon color="#FFF" size="28">mdi-arrow-right</v-icon>
          </div>
        </v-col>
      </v-row>
      
      <v-row v-if="innerWidth < 960" class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <v-img :src="images.mockup_2" alt="mockup_2"></v-img>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">資訊管理系統</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">依照需求進行各類網站建置、語系切換、特定資訊及功能串接等特色功能。網站類別可分為官方網站、一站式網站、導購式網頁、智慧管理後台等</p>
        </v-col>
      </v-row>
      
      <v-row class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <v-img class="finger" :src="hover[2] ? images.mockup_3_mask : images.mockup_3" @mousemove="onMouseMove" @mouseover="onMouse('over',2)" @mouseleave="onMouse('leave',2)"></v-img>
          <div v-if="hover[2]" :style="{ top: cursorY + 'px', left: cursorX + 'px' }" class="arrow-icon">
            <v-icon color="#FFF" size="28">mdi-arrow-right</v-icon>
          </div>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">客製化網站</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">依照需求進行各類網站建置、語系切換、特定資訊及功能串接等特色功能。網站類別可分為官方網站、一站式網站、導購式網頁、智慧管理後台等</p>
        </v-col>
      </v-row>
      
      <v-row v-if="innerWidth >= 960" class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">設計專項團隊</p>
          <p class="styleT4 pt-4" style="line-height:28px">系統開發以 Wireframe 進行初期討論，最終提供完整 UI介面設計，視需求製作上架圖文、系統推廣短片、海報、周邊商品等</p>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <v-img class="finger" :src="hover[3] ? images.mockup_4_mask : images.mockup_4" @mousemove="onMouseMove" @mouseover="onMouse('over',3)" @mouseleave="onMouse('leave',3)"></v-img>
          <div v-if="hover[3]" :style="{ top: cursorY + 'px', left: cursorX + 'px' }" class="arrow-icon">
            <v-icon color="#FFF" size="28">mdi-arrow-right</v-icon>
          </div>
        </v-col>
      </v-row>
      
      <v-row v-if="innerWidth < 960" class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <v-img :src="images.mockup_4" alt="mockup_4"></v-img>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">設計專項團隊</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">系統開發以 Wireframe 進行初期討論，最終提供完整 UI介面設計，視需求製作上架圖文、系統推廣短片、海報、周邊商品等</p>
        </v-col>
      </v-row>

      <v-row class="ma-1 my-8 flexNone justifyCenter">
        <v-row class="ma-0 mx-3 pa-1 col-10 col-md-auto justifyLeft">
          <v-icon class="mr-2" color="#4880C8">mdi-email</v-icon>
          <p>chihta.software@gmail.com</p>
        </v-row>
        <v-row class="ma-0 mx-3 pa-1 col-10 col-md-auto justifyLeft">
          <v-icon class="mr-2" color="#4880C8">mdi-briefcase-search</v-icon>
          <p>統一編號 83210973</p>
        </v-row>
        <v-row class="ma-0 mx-3 pa-1 col-10 col-md-auto justifyLeft">
          <v-icon class="mr-2" color="#4880C8">mdi-map-marker-radius</v-icon>
          <p>新北市鶯歌區尖山路101巷4弄2號</p>
        </v-row>
      </v-row>
    </div>
  </div>
</template>

<script>
import Editor from '@/components/Editor.vue';
export default {
  name: 'CaseStudy',
  components: {Editor},
  data:() => ({
    scrollNum: 0, //滾動距離
    hover: [false, false, false, false],
    cursorX: 0,
    cursorY: 0,
    viewStyle:{},
    innerWidth: 0,
    images:{
      logo_chihta: require('@/assets/image/logo_chihta.png'),
      action_04: require('@/assets/image/action_04.png'),
      serve_01: require('@/assets/image/serve_01.png'),
      mockup_1: require('@/assets/image/mockup_1.png'),
      mockup_1_mask: require('@/assets/image/mockup_1_mask.png'),
      mockup_2: require('@/assets/image/mockup_2.png'),
      mockup_2_mask: require('@/assets/image/mockup_2_mask.png'),
      mockup_3: require('@/assets/image/mockup_3.png'),
      mockup_3_mask: require('@/assets/image/mockup_3_mask.png'),
      mockup_4: require('@/assets/image/mockup_4.png'),
      mockup_4_mask: require('@/assets/image/mockup_4_mask.png'),
      imgLong: require('@/assets/image/imgLong.png'),
    },
  }),
  created(){
    this.calculateWindows()
  },
  mounted() {
    sessionStorage.setItem('login', 'false')
  },
  methods:{
    onMouse(move,i){
      if(move=='over'){
        this.$set(this.hover, i, true);
      }else if(move=='leave'){
        this.$set(this.hover, i, false);
      }
    },
    onMouseMove(event) {
      this.cursorX = event.clientX+12; // 贴近鼠标
      this.cursorY = event.clientY-50; // 贴近鼠标
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    calculateWindows(){
      this.innerWidth = window.innerWidth
      this.viewStyle = {height: (window.innerHeight-((window.innerWidth > 959) ? 100 : 121)) + 'px'}
    },
  }
}
</script>

<style scoped>
  .image-container {
    position: relative;
    display: inline-block;
  }

  .arrow-icon {
    position: absolute;
    pointer-events: none; /* 避免干扰鼠标事件 */
    background: #B77BF2;
    border-radius: 50%;
    padding: 16px;
    transform: translate(0%, 0%); /* 使箭头标志位于鼠标右上角 */
  }

  .carousel {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }

  .carousel-inner {
    display: flex;
    animation: scroll 8s linear infinite; /* 根据需要调整动画时间 */
  }

  .image-container {
    display: flex;
    width: calc(1800px * 5); /* 五张图片的总宽度 */
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-60%); /* 滚动五分之三，即三张图片的宽度 */
    }
  }
</style>