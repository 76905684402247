class ColorTool {
  static get isInline() {
    return true;
  }

  static get sanitize() {
    return {
      span: {
        style: true,
      }
    };
  }

  constructor({ api }) {
    this.api = api;
    this.button = null;
    this.state = false;

    this.tag = 'SPAN';
    this.class = 'cdx-color';
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML = 'Color';
    this.button.classList.add(this.api.styles.inlineToolButton);
    this.button.addEventListener('click', () => this.toggleColor());
    return this.button;
  }

  surround(range) {
    if (!range) {
      return;
    }

    const selectedText = range.extractContents();
    const span = document.createElement(this.tag);
    span.style.color = this.state ? '#0F0' : '#F00'; // 示例：红色和黑色切换
    span.appendChild(selectedText);
    range.insertNode(span);
  }

  checkState() {
    const text = this.api.selection.findParentTag(this.tag);
    this.state = !!text;
    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, this.state);
  }

  toggleColor() {
    this.state = !this.state;
  }
}

export default ColorTool;
