class AlignTool {
  static get isInline() {
    return true;
  }

  constructor({ api }) {
    this.api = api;
    this.button = null;
    this.state = {
      align: 'left'
    };
  }

  render() {
    const alignButtons = ['left', 'center', 'right'].map(align => {
      const button = document.createElement('button');
      button.type = 'button';
      button.innerHTML = align.charAt(0).toUpperCase() + align.slice(1);
      button.classList.add('align-button', align);
      button.addEventListener('click', () => this.setAlign(align));
      return button;
    });

    const wrapper = document.createElement('div');
    alignButtons.forEach(button => {
      wrapper.appendChild(button);
    });

    return wrapper;
  }

  setAlign(align) {
    this.state.align = align;
    this.applyAlign();
  }

  applyAlign() {
    const { anchor, focus } = this.api.selection.getRange();

    if (!anchor || !focus) return;

    const selectedBlocks = this.api.selection.getBlocks();

    selectedBlocks.forEach(block => {
      const { tool } = block;
      if (tool && tool.includes('header')) return;

      const newData = {
        ...block.data,
        align: this.state.align
      };
      this.api.blocks.update(block.id, { data: newData });
    });

    this.api.selection.restoreRange();
  }

  checkState() {
    const selectedBlocks = this.api.selection.getBlocks();

    if (selectedBlocks.length === 0) return;

    const firstBlock = selectedBlocks[0];
    this.state.align = firstBlock.data.align || 'left';

    const buttons = document.querySelectorAll('.align-button');
    buttons.forEach(button => {
      button.classList.remove('active');
    });

    const activeButton = document.querySelector(`.align-button.${this.state.align}`);
    if (activeButton) {
      activeButton.classList.add('active');
    }
  }
}

export default AlignTool;
