<template>
  <!-- <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->
  <div>
    <div v-if="loading" class="spinner" >
      <div style="height: calc(100vh); width: 100%; background: #4880C8; display: flex; align-items: center; justify-content: center">
        <div class="container_opacity">
          <div v-if="showTime <= 1" class="circle_inner_big"></div>
          <div v-if="showTime >= 1 && showTime <= 8" class="text-container">
            <div class="circle_outer" style="opacity: 0; animation: fadeIn 2s forwards"></div>
            <div class="circle_inner"></div>
            <div class="mask" :class="{ 'slide-out': showTime >= 4 }"
              style="opacity: 0; animation: fadeIn 2s forwards"></div>
            <h4 v-if="showTime >= 4" class="slide-in-text">
              智達軟體設計
            </h4>
          </div>
        </div>
      </div>
    </div>
    
    <div v-if="!loading">
      <v-app style="margin: 0px; overflow-x:hidden; overflow-y:hidden" >
        <router-view/>
        <div class="ma-0 pa-0" style="background:#4880C8; width:100%">
          <v-row class="ma-1" justify="center" style="min-height:280px; padding-top:60px">
            <v-col class="pa-0 col-10 col-sm-5 col-md-3 col-xl-2" style="align-content:center">
              <v-img :src="logoChihtaWhite" alt="logo_chihta_white"></v-img>
            </v-col>
            <v-col class="pa-0 col-10 col-sm-5 col-md-auto" style="align-content:center; color:white;">
              <v-row class="ma-0 py-1 col-md-auto justifyLeft">
                <v-icon class="mr-2" color="white">mdi-email</v-icon>
                <p>chihta.software@gmail.com</p>
              </v-row>
              <v-row class="ma-0 py-1 col-md-auto justifyLeft" style="flex-wrap: nowrap;">
                <v-icon class="mr-2" color="white">mdi-briefcase-search</v-icon>
                <p>統一編號 83210973</p>
              </v-row>
              <v-row class="ma-0 py-1 col-md-auto justifyLeft" style="flex-wrap: nowrap;">
                <v-icon class="mr-2" color="white">mdi-map-marker-radius</v-icon>
                <p>新北市鶯歌區尖山路101巷4弄2號</p>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="my-0 mx-2" justify="center" style="padding-bottom:60px; color:white">
            <v-col class="pa-0 col-10">
              <p style="font-size: 12px" @click="$refs.LoginPage.open()">
                © Copyright {{ currentYear }} CHIHTA SOFTWARE DESIGN CO., LTD., All Rights Reserved 請勿任意連結、轉載
              </p>
            </v-col>
          </v-row>
        </div>
        <LoginPage ref="LoginPage" :onSave="onLoginPage"/>
      </v-app>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import LoginPage from '@/dialogs/LoginPage'

export default {
  name: 'App',
  components: {LoginPage},
  data:() => ({
    loading: false,
    showTime: 0,
    logoChihtaWhite: require('@/assets/image/logo_chihta_white.png'),
    currentYear: new Date().getFullYear()
  }),
  mounted() {
    this.loading = true;
    setTimeout(() => { this.loading = false }, 8000);
    this.timer = setInterval(() => {
      this.showTime++;
    }, 1000); // 每秒增加一次

    // 取消计时器
    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.timer);
    });
  },
  methods:{
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    onLoginPage(){
      sessionStorage.setItem('login', 'true')
      this.redirectPath('/chihta')
    },
  },

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
