<template>
  <div>
    <div id="router" style="padding-top: 5%">
      <p class="styleH2">聯絡我們</p>
      <p class="styleT2 px-6" style="color:#737373">發送信件至 chihta.software@gmail.com 我們將儘速回應您。</p>
      <v-row class="justifyCenter" style="margin: 1% 8%">
        <!-- https://www.letswrite.tw/mailto-link/ -->
        <a class="ma-2" href="mailto:chihta.software@gmail.com">
          <v-btn class="ma-0 py-0 px-6 styleBtn custom-btn" outlined>
            <v-row class="ma-0" style="align-items:center">
              <v-icon class="pa-2">mdi-email-outline</v-icon>
              <h4 class="center">快速發信聯絡</h4>
            </v-row>
          </v-btn>
        </a>
      </v-row>
      <v-row class="justifyCenter" style="margin: 1% 8% 10% 8%">
        <v-col class="col-12 col-md-6 pa-5" align="center">
          <v-img :src="images.action_05" width=400 alt="action_05"></v-img>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  props: {},
  data:() => ({
    viewStyle:{},
    innerWidth: 0,
    images:{
      action_05: require('@/assets/image/action_05.png'),
    },
  }),
  mounted() {
    document.documentElement.scrollTop = 0;
  },
  created(){
    this.calculateWindows()
  },
  methods:{
    calculateWindows(){
      this.innerWidth = window.innerWidth
      this.viewStyle = {height: (window.innerHeight-((window.innerWidth > 959) ? 100 : 121)) + 'px'}
    },
  }
}
</script>

