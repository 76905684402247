import Vue from 'vue'
import VueRouter from 'vue-router'
import FrontPage from '../views/FrontPage.vue'
import HomePage from '../views/HomePage.vue'
import Service from '../views/Service.vue'
import CaseStudy from '../views/CaseStudy.vue'
import CaseStudy1 from '../views/CaseStudy1.vue'
import CaseStudy2 from '../views/CaseStudy2.vue'
import Location from '../views/Location.vue'
import ContactUs from '../views/ContactUs.vue'
import Chihta from '../views/Chihta.vue'

import StageHome from '../views/StageHome.vue'
import StageFront from '../views/StageFront.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'FrontPage',
    component: FrontPage,
    children: [
      {
        path: '/homePage',
        name: 'HomePage',
        component: HomePage
      },
      {
        path: '/service',
        name: 'Service',
        component: Service
      },
      {
        path: '/caseStudy',
        name: 'CaseStudy',
        component: CaseStudy
      },
      // 放大鏡
      {
        path: '/caseStudy1',
        name: 'CaseStudy1',
        component: CaseStudy1
      },
      // 編輯器
      {
        path: '/caseStudy2',
        name: 'CaseStudy2',
        component: CaseStudy2
      },
      {
        path: '/location',
        name: 'Location',
        component: Location
      },
      {
        path: '/contactUs',
        name: 'ContactUs',
        component: ContactUs
      },
      {
        path: '/chihta',
        name: 'Chihta',
        component: Chihta
      },
    ]
  },
  {
    path: '/',
    name: 'StageHome',
    component: StageHome,
    children: [
      {
        path: '/stageFront',
        name: 'StageFront',
        component: StageFront
      },
    ]
  },

  {path: '*', redirect: '/' }
]

const router = new VueRouter({
  routes
})

export default router
