<template>
  <div class="container" @wheel="handleWheel">
    <v-row class="ma-0">
      <v-col class="pa-0 col-1" style="margin-top:15%; display: flex; flex-direction: column;; align-content: flex-end; align-items: center;">
        <div v-for="(page, index) in totalPages" :key="index">
          <div class="ma-2 pa-1 finger" style="height:36px; width:36px; border-radius: 50%"
          :style="{'border-right' : (currentPage == index) ? '2px solid #737373' : '0px'}" @click="currentPage = index">
            <div class="ma-1" style="height:18px; width:18px; border-radius: 18px"
            :style="{'background' : (currentPage == index) ? '#737373' : '#EAEAEA'}">
            </div>
          </div>
        </div>
      </v-col>
      <v-col class="pa-0 col-10" style="position: relative;" :style="[{'height' : 'calc(100vh - 128px)'}]">
        <div v-if="currentPage == 0">
          <p class="styleH4" :class="['box', { change: show }]">面對資訊系統的建置/優化</p>
          <p class="styleH2" :class="['box', { change: show }]">您是否也有這種疑問？</p>
          <v-row class="ma-1 mt-5 justifyCenter" style="padding:5% 0%;">
            <v-col class="col-11 col-md-6">
              <v-img :src="images.action_01" alt="action_01"></v-img>
            </v-col>
            <v-col class="col-11 col-md-5 py-6" align-self="center">
              <v-row v-for="(showStage, index) in showStages0" :key="index" :class="['box', { change: showStage }]" align="center">
                <v-col cols="2" class="px-1 py-2">
                  <v-img :src="images[`icon_doubt_0${index + 1}`]" width=75 :alt="`icon_doubt_0${index + 1}`"></v-img>
                </v-col>
                <v-col cols="10" class="pa-0 pl-2" align="left">
                  <p class="styleT2">{{ messages[index].title }}</p>
                  <p class="styleT4">{{ messages[index].content }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div v-if="currentPage == 1">
          <v-row class="justifyCenter" style="margin: 8% 0% 5% 0%; padding:4% 5%">
            <v-col class="col-11 col-md-3 pa-5" align="center">
              <div style="width:200px">
                <v-img :src="images.logo_chihta" alt="logo_chihta"></v-img>
              </div>
              <p class="styleT4 mt-5" style="font-size:18px">新北市鶯歌區尖山路101巷4弄2號</p>
              <p class="styleT4" style="font-size:18px">chihta.software@gmail.com</p>
            </v-col>
            <v-col class="col-11 col-md-5 pa-5" align-self="center" align="left">
              <v-row class="ma-0 pa-0" :class="['box', { change: show }]">
                <p class="styleH4" style="color:#4880C8">智達軟體設計</p>
                <p class="styleH4">・解決您的困擾</p>
              </v-row>
              <v-row class="ma-0 pa-0" :class="['box', { change: show }]">
                <p class="styleT4 pt-2" style="color:#737373">本公司成立於2021年，以開啟著的圓形開關鍵，象徵本公司為客戶提供之軟體設計服務及視覺設計服務 Always ON 的決心</p>
              </v-row>
              <div class="mx-0 pa-0 mt-4" style="width:220px;background:#4880C8" :class="['box', { change: showStage1 }]">
                <h6 class="styleT4 py-auto pl-3" style="color:#FFF; font-size:1.25rem">服務項目</h6>
              </div>
              <v-row class="ma-0 pa-0" :class="['box', { change: showStage1 }]">
                <p class="styleT4 pt-2" style="color:#737373">資訊軟體服務、系統建置服務、資訊處理服務、一般廣告服務、產品設計服務，為客戶打造網路應用程式、行動應用程式、網站設計與建制、企業內部系統</p>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div v-if="currentPage == 2">
          <div class="mx-0 mt-8 mb-6 pa-0">
            <p class="styleH4" :class="['box', { change: show }]">深耕軟體及設計產業</p>
            <p class="styleH2" :class="['box', { change: show }]">三大領域・完整服務</p>
          </div>
          <v-row style="justify-content:space-around; margin: 4% 12% 0% 12%">
            <v-col class="ma-2 pa-0 col-11 col-md-3 floating-box" style="background:#FFF; box-shadow: 3px 5px 9px -1px #00000014;"
            :class="['box', { change: showStages2[0] }]">
              <div class="pt-6 pb-3 justifyCenter" style="border-bottom: 1px solid #737373">
                <v-img class="mx-auto" :src="images.service_01" width=260 alt="logo_chihta"></v-img>
              </div>
              <div class="pa-6 text-left">
                <p class="styleT2">醫療領域</p>
                <p class="styleT4 pt-1">給藥追蹤系統｜手術室看板｜診所APP｜智能管理平台</p>
              </div>
            </v-col>
            <v-col class="ma-2 pa-0 col-11 col-md-3 floating-box" style="background:#FFF; box-shadow: 3px 5px 9px -1px #00000014;"
            :class="['box', { change: showStages2[1] }]">
              <div class="pt-6 pb-3 justifyCenter" style="border-bottom: 1px solid #737373">
                <v-img class="mx-auto" :src="images.service_02" width=260 alt="logo_chihta"></v-img>
              </div>
              <div class="pa-6 text-left">
                <p class="styleT2">教育領域</p>
                <p class="styleT4 pt-1">校園智慧校園行動APP｜認證考試報名系統｜監考系統｜考試系統</p>
              </div>
            </v-col>
            <v-col class="ma-2 pa-0 col-11 col-md-3 floating-box" style="background:#FFF; box-shadow: 3px 5px 9px -1px #00000014;"
            :class="['box', { change: showStages2[2] }]">
              <div class="pt-6 pb-3 justifyCenter" style="border-bottom: 1px solid #737373">
                <v-img class="mx-auto" :src="images.service_03" width=260 alt="logo_chihta"></v-img>
              </div>
              <div class="pa-6 text-left">
                <p class="styleT2">其他領域</p>
                <p class="styleT4 pt-1">硬件整合｜物聯網開發｜智慧工廠及智慧城市</p>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="currentPage == 3">
          <v-row class="justifyCenter" style="margin: 10% 8% 0% 8%">
            <v-col class="text-left pr-6 col-12 col-md-6" align-self="center">
              <p class="styleH4" :class="['box', { change: show }]">解決您對於專案執行流程的各項疑惑</p>
              <p class="styleH2" :class="['box', { change: show }]">專業客製化軟體開發團隊</p>
              <p class="styleT2" :class="['box', { change: show }]" style="color:#737373">依照專案特性與範疇建立專屬開發團隊，期間以使用者體驗為導向進行系統設計，滿足各式使用者的作業需求，用以提高作業效率與同業競爭力</p>
              <v-row class="ma-0 pt-4" :class="['box', { change: show }]">
                <v-btn class="py-4 px-6 styleBtn custom-btn" outlined @click="redirectPath('/service')">
                  <h4 class="center">查看服務項目</h4>
                  <v-icon>mdi-arrow-right-thin</v-icon>
                </v-btn>
                <v-btn v-if="innerWidth >= 588" class="py-4 px-6 ml-2 styleBtn custom-btn" outlined @click="redirectPath('/contactUs')">
                  <h4 class="center">發信聯絡智達</h4>
                  <v-icon>mdi-arrow-right-thin</v-icon>
                </v-btn>
              </v-row>
            </v-col>
            <v-col class="px-6 col-11 col-md-4" align="center">
              <v-img :src="images.action_02" width=400 alt="action_02"></v-img>
            </v-col>
          </v-row>

          <v-row class="ma-1 my-8 justifyCenter" style="padding-top:28px">
            <v-row class="ma-0 mx-3 pa-1 col-10 col-md-auto justifyLeft">
              <v-icon class="mr-2" color="#4880C8">mdi-email</v-icon>
              <p>chihta.software@gmail.com</p>
            </v-row>
            <v-row class="ma-0 mx-3 pa-1 col-10 col-md-auto justifyLeft">
              <v-icon class="mr-2" color="#4880C8">mdi-briefcase-search</v-icon>
              <p>統一編號 83210973</p>
            </v-row>
            <v-row class="ma-0 mx-3 pa-1 col-10 col-md-auto justifyLeft">
              <v-icon class="mr-2" color="#4880C8">mdi-map-marker-radius</v-icon>
              <p>新北市鶯歌區尖山路101巷4弄2號</p>
            </v-row>
          </v-row>
        </div>
        <v-row v-if="currentPage+1 < totalPages" class="ma-0 hover-btn" style="position: absolute; bottom: 20%; right: 0px;">
          <div class="pa-0 finger" outlined style="height: 48px !important; min-width: 48px !important; border-radius:50%; border:none"
          @click="scrollNext()">
            <v-icon class="pa-0 hover-icon" size="48" color="#A6A6A6">mdi-arrow-down-circle</v-icon>
          </div>
        </v-row>
      </v-col>
      <v-col class="pa-0 col-1">
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'StageFront',
  mounted() {
    setTimeout(() => { this.show = true; }, 500); //delay 1s
    setTimeout(() => { this.showElementsSequentially(0); }, 1000); //delay 1s
  },
  data:() => ({
    currentPage: 0,
    totalPages: 4, // 假设有 5 页
    isScrolling: false,
    innerWidth: 0,
    innerHeight: 0,
    images:{
      logo_chihta: require('@/assets/image/logo_chihta.png'),
      action_01: require('@/assets/image/action_01.png'),
      action_02: require('@/assets/image/action_02.png'),
      service_01: require('@/assets/image/service_01.png'),
      service_02: require('@/assets/image/service_02.png'),
      service_03: require('@/assets/image/service_03.png'),
      icon_doubt_01: require('@/assets/image/icon_doubt_01.png'),
      icon_doubt_02: require('@/assets/image/icon_doubt_02.png'),
      icon_doubt_03: require('@/assets/image/icon_doubt_03.png'),
      icon_doubt_04: require('@/assets/image/icon_doubt_04.png'),
    },
    show: false,
    showStages0: [false, false, false, false],
    showStage1: false,
    showStages2: [false, false, false],
    messages: [
      { title: "不知道從何開始？", content: "舊系統架構複雜，功能及分支眾多" },
      { title: "沒有時間多方尋找廠商？", content: "如何作為系統設計與開發中間的橋樑" },
      { title: "如何提高使用者體驗？", content: "希望使用流程較為直覺，易操作" },
      { title: "擔心系統維護與資訊安全？", content: "找尋好配合的廠商且能夠快速給予協助" }
    ],
  }),
  watch:{
    "currentPage":function(){
      this.show = false
      setTimeout(() => { this.show = true; }, 500); //delay 1s
      if(this.currentPage == 0){
        this.showStages0 = [false, false, false, false]
        setTimeout(() => { this.showElementsSequentially(0); }, 1000); //delay 1s
      }else if(this.currentPage == 1){
        this.showStage1 = false
        setTimeout(() => { this.showStage1 = true; }, 1000); //delay 1s
      }else if(this.currentPage == 2){
        this.showStages2 = [false, false, false]
        setTimeout(() => { this.showElementsSequentially(2); }, 1000); //delay 1s
      }
    },
  },
  computed: {
    nextPage() {
      return Math.min(this.totalPages - 1, this.currentPage + 1);
    },
    prevPage() {
      return Math.max(0, this.currentPage - 1);
    },
  },
  created(){
    this.calculateWindows()
  },
  methods: {
    calculateWindows(){
      this.innerWidth = window.innerWidth
      this.innerHeight = window.innerHeight-100
      // this.viewStyle = {height: (window.innerHeight-((window.innerWidth > 959) ? 100 : 121)) + 'px'}
    },
    handleWheel(event) {
      if (this.isScrolling) return;
      this.isScrolling = true;

      if (event.deltaY > 0) {
        this.scrollNext();
      } else {
        this.scrollPrev();
      }

      setTimeout(() => {
        this.isScrolling = false;
      }, 1000); // 调整锁定时间间隔
    },
    scrollNext() {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
      }
    },
    scrollPrev() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
    showElementsSequentially(num) {
      this['showStages' + num].forEach((_, index) => {
        setTimeout(() => {
          this.$set(this['showStages' + num], index, true);
        }, index * 500); // 每個元素間隔0.5秒出現
      });
    }
  },
};
</script>

<style>

  .box {
    transition: all 1s ease; /* 設置過渡效果 */
    transform: translateY(100%); /* 初始狀態：從下方 */
    opacity: 0; /* 初始狀態：透明 */
  }

  .change {
    transform: translateY(0); /* 最終狀態：移動到原位 */
    opacity: 1; /* 最終狀態：完全不透明 */
  }

  .container {
    height: 100%;
    overflow: hidden;
  }

  .page-container {
    height: 100%;
  }

  .page {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ccc;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .floating-box {
    transition: transform 0.3s ease-in-out;
  }
  .floating-box:hover {
    transform: translateY(-30px); /* 上下浮動的距離 */
  }

  .custom-btn {
    background-color: #4880C8;
    color: white !important;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  .custom-btn:hover {
    background-color: #333;
  }
  .custom-btn .mdi-arrow-right-thin {
    transition: transform 0.3s ease;
  }
  .custom-btn:hover .mdi-arrow-right-thin {
    transform: scale(1.15) translateX(3px); /* 放大並向右移動 */
  }
  .hover-btn:hover .hover-icon {
    color: #4880C8 !important;
  }
</style>