<template>
  <div>
    <div id="router" class="pt-10" :style="viewStyle">
      <v-row class="justifyCenter" style="margin: 1% 8%">
        <v-col class="text-left pr-6 col-11 col-md-5" align-self="center">
          <p class="styleH4">資訊系統的建置/優化</p>
          <p class="styleH2">提供專業系統引導與規劃</p>
          <p class="styleT2" style="color:#737373">每個專案皆配有專案經理，明確訂定需求與權責範圍，統一窗口討論系統形象設計、程式建置與上線前測試。</p>
          <v-row class="ma-0 pt-4">
            <v-btn class="pa-4 styleBtn" outlined style="color:#4880C8" @click="redirectPath('/contactUs')">
              <h4 class="center">立即詢問</h4>
              <v-icon class="pa-2">mdi-email-outline</v-icon>
            </v-btn>
          </v-row>
        </v-col>
        <v-col class="px-6 col-11 col-md-4" align="center">
          <v-img :src="images.action_04" width=400 alt="action_04"></v-img>
        </v-col>
      </v-row>
      
      <v-row class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <div class="image-container" ref="imageContainer1">
            <v-img :src="images.mockup_1" alt="mockup_1" ref="image1"></v-img>
            <div class="zoom pa-0" ref="zoom1"></div>
          </div>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">客製化行動裝置/APP</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">本App為亞東科技大學全校教職員生及校友提供具智慧化與整合性的資訊服務，系統支援接收推播資訊功能、個人資訊管理及整合性資訊資源</p>
        </v-col>
      </v-row>
      
      <v-row v-if="innerWidth >= 960" class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">資訊管理系統</p>
          <p class="styleT4 pt-4" style="line-height:28px">本專案重新梳理/優化操作流程，同時追加特色功能以改善使用者多系統切換的操作痛點。五大系統分別為：考試報名系統、管理後台、題庫系統、監考系統及考試系統</p>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <div class="image-container" ref="imageContainer2">
            <v-img :src="images.mockup_2" alt="mockup_2" ref="image2"></v-img>
            <div class="zoom pa-0" ref="zoom2"></div>
          </div>
        </v-col>
      </v-row>
      
      <v-row v-if="innerWidth < 960" class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <v-img :src="images.mockup_2" alt="mockup_2"></v-img>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">資訊管理系統</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">依照需求進行各類網站建置、語系切換、特定資訊及功能串接等特色功能。網站類別可分為官方網站、一站式網站、導購式網頁、智慧管理後台等</p>
        </v-col>
      </v-row>
      
      <v-row class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <div class="image-container" ref="imageContainer3">
            <v-img :src="images.mockup_3" alt="mockup_3" ref="image3"></v-img>
            <div class="zoom pa-0" ref="zoom3"></div>
          </div>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">客製化網站</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">依照需求進行各類網站建置、語系切換、特定資訊及功能串接等特色功能。網站類別可分為官方網站、一站式網站、導購式網頁、智慧管理後台等</p>
        </v-col>
      </v-row>
      
      <v-row v-if="innerWidth >= 960" class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">設計專項團隊</p>
          <p class="styleT4 pt-4" style="line-height:28px">系統開發以 Wireframe 進行初期討論，最終提供完整 UI介面設計，視需求製作上架圖文、系統推廣短片、海報、周邊商品等</p>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <div class="image-container" ref="imageContainer4">
            <v-img :src="images.mockup_4" alt="mockup_4" ref="image4"></v-img>
            <div class="zoom pa-0" ref="zoom4"></div>
          </div>
        </v-col>
      </v-row>
      
      <v-row v-if="innerWidth < 960" class="justifyCenter" style="margin:2% 8%; padding:0%; background:#FFF">
        <v-col class="ma-0 pa-5 col-12 col-md-6 justifyCenter">
          <v-img :src="images.mockup_4" alt="mockup_4"></v-img>
        </v-col>
        <v-col class="ma-0 pa-5 col-12 col-md-5 text-left" align-self="center">
          <p class="styleH4">設計專項團隊</p>
          <p class="styleT4 pt-4" style="height:160px; line-height:28px">系統開發以 Wireframe 進行初期討論，最終提供完整 UI介面設計，視需求製作上架圖文、系統推廣短片、海報、周邊商品等</p>
        </v-col>
      </v-row>

      <v-row class="ma-1 my-8 flexNone justifyCenter">
        <v-row class="ma-0 mx-3 pa-1 col-10 col-md-auto justifyLeft">
          <v-icon class="mr-2" color="#4880C8">mdi-email</v-icon>
          <p>chihta.software@gmail.com</p>
        </v-row>
        <v-row class="ma-0 mx-3 pa-1 col-10 col-md-auto justifyLeft">
          <v-icon class="mr-2" color="#4880C8">mdi-briefcase-search</v-icon>
          <p>統一編號 83210973</p>
        </v-row>
        <v-row class="ma-0 mx-3 pa-1 col-10 col-md-auto justifyLeft">
          <v-icon class="mr-2" color="#4880C8">mdi-map-marker-radius</v-icon>
          <p>新北市鶯歌區尖山路101巷4弄2號</p>
        </v-row>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseStudy',
  components: {},
  data:() => ({
    scrollNum: 0, //滾動距離
    viewStyle:{},
    innerWidth: 0,
    images:{
      logo_chihta: require('@/assets/image/logo_chihta.png'),
      action_04: require('@/assets/image/action_04.png'),
      serve_01: require('@/assets/image/serve_01.png'),
      mockup_1: require('@/assets/image/mockup_1.png'),
      mockup_1_mask: require('@/assets/image/mockup_1_mask.png'),
      mockup_2: require('@/assets/image/mockup_2.png'),
      mockup_2_mask: require('@/assets/image/mockup_2_mask.png'),
      mockup_3: require('@/assets/image/mockup_3.png'),
      mockup_4: require('@/assets/image/mockup_4.png'),
    },
  }),
  created(){
    this.calculateWindows()
  },
  mounted() {
    sessionStorage.setItem('login', 'false')
    this.setupZoom(this.$refs.imageContainer1, this.$refs.image1, this.$refs.zoom1, this.images.mockup_1);
    this.setupZoom(this.$refs.imageContainer2, this.$refs.image2, this.$refs.zoom2, this.images.mockup_2);
    this.setupZoom(this.$refs.imageContainer3, this.$refs.image3, this.$refs.zoom3, this.images.mockup_3);
    this.setupZoom(this.$refs.imageContainer4, this.$refs.image4, this.$refs.zoom4, this.images.mockup_4);
  },
  methods:{
   setupZoom(container, imgElement, zoomElement, imageUrl) {
      const zoomFactor = 1.3; // Define the zoom factor for zooming out

      container.addEventListener('mousemove', (e) => {
        const rect = container.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        
        const zoomSize = 150; // Size of the zoom element (width and height)
        const zoomHalfSize = zoomSize / 2;

          // Calculate the translation for the zoom element
        zoomElement.style.transform = `translate(${x - zoomHalfSize}px, ${y - zoomHalfSize - 420}px) scale(1)`;
        zoomElement.style.backgroundImage = `url(${imageUrl})`;
        // zoomElement.style.backgroundPosition = `-${x * zoomFactor + 45}px -${y * zoomFactor - 20}px`;
        zoomElement.style.backgroundPosition = `-${x * zoomFactor-100}px -${y * zoomFactor-100}px`;
        zoomElement.style.backgroundSize = `${imgElement.width * zoomFactor}px ${imgElement.height * zoomFactor}px`;
      });

      container.addEventListener('mouseenter', () => {
        zoomElement.style.transform = 'scale(1)';
      });

      container.addEventListener('mouseleave', () => {
        zoomElement.style.transform = 'scale(0)';
      });
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    calculateWindows(){
      this.innerWidth = window.innerWidth
      this.viewStyle = {height: (window.innerHeight-((window.innerWidth > 959) ? 100 : 121)) + 'px'}
    },
  }
}
</script>
<style scoped>
.image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.image-container img {
  display: block;
  width: 100%;
  /* transition: transform 0.2s ease; */
}
.image-container .zoom {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  /* transition: transform 0.2s ease; */
  width: 150px;
  height: 150px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transform: scale(0);
  overflow: hidden;
  background-repeat: no-repeat;
}
</style>